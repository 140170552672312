<template>
    <div>
    <div class="" v-if="dataOnload">
        <div class="fl-x-cc">
            <h4 class="text-7">{{dataOnload.species_name}}</h4>
        </div>
        <h6 class="text-2">Content</h6>
        <p class="text-4">Try with our prediction for diagnosis.</p>
        <div class="col-lg-12 b-1 mt-3">

            <div class="">
                <div class=" ">
                    <div class="card p-1">
                        <div class="card-header" @click="toggleExpand">
                            <p class="text-seventh font-weight-400 mb-0 p-2 text-center">Normal value of {{dataOnload.species_name}}</p>
                        </div>
                        <div class="card-body" v-if="expanded">
                            <p class="text-center pt-2 font-weight-300 ">

                                <span v-for="(item,index) in normal_values" :key="index">{{ item }}<br></span>

                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <p class="text-2 text-2 font-weight-500 text-bold pt-4">Find Here,</p>
        <div class="row">
            <div class="col-lg-6" v-if="clinical">
                <validated-vue-select multiple :options="clinical" class="" placeholder="choose some sign"
                                      label="Select Clinical signs" v-model="model.clinical_sign"></validated-vue-select>
            </div>
            <div class="col-lg-6" v-if="ascaltaions">
                <validated-vue-select multiple :options="ascaltaions" placeholder="choose some sign"
                                    v-model="model.ascalation"  label="Ascalations or Abdominal Palpations"></validated-vue-select>
            </div>
            <div class="col-lg-6" v-if="cbc_list">
                <validated-vue-select multiple :options="cbc_list" placeholder="choose some sign"
                                      v-model="model.cbc_list" label="CBC List"></validated-vue-select>
            </div>
            <div class="col-lg-6" v-if="urinalysis">
                <validated-vue-select multiple :options="urinalysis" placeholder="choose some sign"
                                    v-model="model.urinalysis"   label="Urinalysis"></validated-vue-select>
            </div>
            <div class="col-lg-6" v-if="findings">
                <validated-vue-select multiple :options="findings" placeholder="choose some sign"
                                    v-model="model.findings"  label="Findings"></validated-vue-select>
            </div>
            <div class="col-lg-6" v-if="blood_chemistry">
                <validated-vue-select multiple :options="blood_chemistry" placeholder="choose some sign"
                                    v-model="model.blood_chemistry"  label="Blood Chemistry"></validated-vue-select>
            </div>
            <div class="col-lg-12 fl-x-cc mt-4">
                <div class="col-lg-12 fl-x-cc mt-4">
                    <btn color="secondary" text="Diagonose " border-radius="1" class="px-4"
                         @click="addItem"></btn>
                </div>
                <modal title="Diagnose Findings" ref="SimpleModalWidth60r" class="of-a thin-scrollbar"
                       :no-close-on-backdrop="true" width="60r" header-color="seventh">
                    <div class="container">
                        <diagonose-findings :items="passingData"></diagonose-findings>
                    </div>
                </modal>
            </div>
        </div>
    </div>
        <div class="fl-x fl-j-c" v-else>
            <div class="fl-x" >
              <loading-animation></loading-animation>
             <p>Loading...</p>
          </div>
        </div>

    </div>
</template>

<script>

import DiagonoseFindings from '@/views/user-home/diagnose-list/DiagonoseFindings';
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'IndividualSpecies',
    components: { DiagonoseFindings },
    data () {
        return {
            dataOnload: '',
            model: {
                species: this.$route.params.id,
                clinical_sign: '',
                findings: '',
                cbc_list: '',
                urinalysis: '',
                blood_chemistry: '',
                ascalation: ''
            },
            passingData: '',
            detailsUrl: urls.userHome.userHomeDiagnose.list,
            addUrl: urls.userHome.userHomeDiagnose.diagnose,
            expanded: false,
            clinical: [

            ],

            ascaltaions: [

            ],

            cbc_list: [

            ],
            urinalysis: [

            ],
            findings: [

            ],
            blood_chemistry: [

            ],
            normal_values: [

            ]

        };
    },
    mounted () {
        this.fetchItem();
    },
    methods: {
        toggleExpand () {
            this.expanded = !this.expanded;
        },
        async addItem () {
            try {
                this.loading = true;
                this.response = await axios.form(this.addUrl, this.model);
                const result = this.response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.passingData = result.data;
                    console.log('result', this.passingData);
                    /* this.$notify('Added Successfully', 'Success', { type: 'success' }); */
                    this.$refs.SimpleModalWidth60r.show();
                    this.loading = false;
                } else {
                    const errors = this.response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async fetchItem () {
            console.log('Fetching details...');
            try {
                const response = await axios.form(this.detailsUrl, { species: this.$route.params.id });
                const result = response.data;

                /* this.content = this.data.content;
                console.log('content', this.content);
                this.suggested_treatment = this.data.suggested_treatment;
                console.log('suggested_treatment', this.suggested_treatment);
                this.images = this.data.images;
                console.log('images', this.images); */
                if (result.status === true) {
                    this.dataOnload = result;
                    console.log('result', this.dataOnload);
                    const formData = result.form_data;
                    console.log('result', formData);

                    this.clinical = formData.clinical;
                    console.log('clinical', this.clinical);
                    this.ascaltaions = formData.ascaltaions;
                    console.log('ascaltaions', this.ascaltaions);
                    this.cbc_list = formData.cbc_list;
                    console.log('cbc_list', this.cbc_list);
                    this.urinalysis = formData.urinalysis;
                    console.log('urinalysis', this.urinalysis);
                    this.findings = formData.findings;
                    console.log('findings', this.findings);
                    this.blood_chemistry = formData.blood_chemistry;
                    console.log('findings', this.findings);
                    this.normal_values = result.normal_values;
                    console.log('normal_values', this.normal_values);
                } else {
                    const errors = result.msg;
                    console.log('error', errors);
                    this.$notify(errors, 'Warning', { type: 'warning' });

                    /* for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    } */
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }

    }
};
</script>

<style scoped>

</style>
